import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_redirect_hxxEaFfrIx from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import slideovers_LDumGYo2KH from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/opt/build/repo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import pwa_icons_Y6agwlnXZq from "/opt/build/repo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/opt/build/repo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_M4DzypTK2c from "/opt/build/repo/node_modules/@pinia-orm/nuxt/dist/runtime/plugin.vue3.mjs";
import auth_client_4lHQFpoKNF from "/opt/build/repo/plugins/auth.client.ts";
import organization_client_BoTZU2Wj8L from "/opt/build/repo/plugins/organization.client.ts";
import pinia_client_l2mvTNw9P0 from "/opt/build/repo/plugins/pinia.client.ts";
import realtime_client_zoDOfIXbuN from "/opt/build/repo/plugins/realtime.client.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
import theme_zupea9J243 from "/opt/build/repo/plugins/theme.ts";
import toast_ysMjUcU7eJ from "/opt/build/repo/plugins/toast.ts";
import turnstile_MFeWIPDeww from "/opt/build/repo/plugins/turnstile.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  auth_redirect_hxxEaFfrIx,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_M4DzypTK2c,
  auth_client_4lHQFpoKNF,
  organization_client_BoTZU2Wj8L,
  pinia_client_l2mvTNw9P0,
  realtime_client_zoDOfIXbuN,
  sentry_client_shVUlIjFLk,
  theme_zupea9J243,
  toast_ysMjUcU7eJ,
  turnstile_MFeWIPDeww
]